import React, { useEffect, useState } from "react"
import Select from "react-select"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  CardImg,
  CardText,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import Swal from "sweetalert2"
import { withTranslation } from "react-i18next"
import constants from "../../config/constants"
import { PostApi } from "apis/PostApi"
import CreateUpdateQuestionModal from "pages/Components/Modal/CreateUpdateQuestionModal";
import QuizQuestionList from "pages/Components/QuizQuestionList";

const CreatePostQuestion = props => {
  const [defaultVisibleTo, setDefaultVisibleTo] = useState();
  const history = useHistory()
  const [addedQuizCount, setAddedQuizCout] = useState(0);
  const [quizQuetions, setQuizQuestions] = useState([]);

  useEffect(() => {
    setDefaultVisibleTo({
      value : constants.VISIBLE_TO["All"],
      label : constants.VISIBLE_TO["All"]
    })
  }, []);

  const onAddViewWuizHandler = () => {
    window.$("#addEditQuizModal").modal("show");
  };

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: "",
      published_date: "",
      type: "AdminQuestion",
      visible_to : constants.VISIBLE_TO.All,
    },
    validationSchema: Yup.object().shape({
      title: Yup.mixed().required(props.t("Required-Message")),
      published_date: Yup.mixed().required(props.t("Required-Message")),
      visible_to: Yup.mixed().required(props.t("Required-Message")),
    }),
    onSubmit: values => {
      const data = new FormData()
      data.append("title", values.title);
      data.append("published_date", values.published_date);
      data.append("type", values.type);
      data.append("visible_to", values.visible_to);
      data.append("questions", JSON.stringify(quizQuetions));
      PostApi.createPost(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/post-questions");
                formClear();
                setDefaultVisibleTo({
                  value : constants.VISIBLE_TO["All"],
                  label : constants.VISIBLE_TO["All"]
                })
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  const formClear = () => {
    validationType.resetForm();
    setDefaultVisibleTo({
      value : constants.VISIBLE_TO["All"],
      label : constants.VISIBLE_TO["All"]
    });
  }

  document.title = props.t("Questionnaires") + " | " + props.t("Stuolio")
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Questionnaires")}
          breadcrumbItem={props.t("Add") + " " + props.t("Questionnaire")}
        />
        <Container fluid={true}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Col sm={12}>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validationType.handleSubmit()
                        return false
                      }}
                    >
                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{props.t("Title")}{" "} <span className="text-danger">*</span></Label>
                          <Input
                            name="title"
                            placeholder={props.t("Title")}
                            type="text"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.title || ""}
                            invalid={
                              validationType.touched.title && validationType.errors.title ? true : false
                            }
                          />
                          {validationType.touched.title && validationType.errors.title ? (
                            <FormFeedback type="invalid">{validationType.errors.title}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{props.t("Scheduled_Date")}{" "} <span className="text-danger">*</span></Label>
                          <Input
                            name="published_date"
                            placeholder={props.t("Scheduled_Date")}
                            type="date"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.published_date || ""}
                            invalid={
                              validationType.touched.published_date && validationType.errors.published_date ? true : false
                            }
                          />
                          {validationType.touched.published_date && validationType.errors.published_date ? (
                            <FormFeedback type="invalid">{validationType.errors.published_date}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{props.t("Visible_To")}
                            {" "} <span className="text-danger">*</span></Label>
                          <Select
                          className={
                            validationType.touched.visible_to &&
                            validationType.errors.visible_to
                              ? "bor "
                              : "text-dark"
                          }
                          styles={{
                            control: styles => ({
                              ...styles,
                            }),
                          }}
                          onChange={e => {
                            validationType.setFieldValue("visible_to", e.value)
                            setDefaultVisibleTo(e)
                          }}
                          value={defaultVisibleTo || ""}
                          onBlur={validationType.handleBlur}
                          name="type"
                          options={Object.keys(constants.VISIBLE_TO).map(
                            value => ({
                              value: value,
                              label: constants.VISIBLE_TO[value]
                            })
                          )}
                          placeholder={
                            props.t("Select_Visible_To")
                          }
                        />
                        {validationType.touched.visible_to && validationType.errors.visible_to ? (
                          <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                            {validationType.errors.visible_to}
                          </div>
                          ) : null}
                        </div>
                      </Col>

                      <div className="form-group mb-4 row">
                        <div className="col-1">
                          <img
                            src="assets/images/plus.png"
                            alt="link"
                            width="15"
                          />
                        </div>
                        <div className="col-11">
                          <a href="#"
                            onClick={onAddViewWuizHandler}
                            className="text-blue"
                          >
                            {addedQuizCount >= 1
                              ? `${props.t("Edit")} ${props.t("Questions")}`
                              : `${props.t("Add")} ${props.t("Questions")}`}
                          </a>
                          {addedQuizCount !== 0 && (
                            <span
                              style={{ color: "#198754" }}
                            >{`(${addedQuizCount} Questions Added)`}</span>
                          )}
                        </div>
                      </div>
                      <CreateUpdateQuestionModal
                        setAddedQuizCout={setAddedQuizCout}
                        setQuizQuestions={setQuizQuestions}
                        maxQuestion={constants.POST_MAX_QUESTIONS}
                        selectedPostType={"AdminQuestion"}
                        isActionBtnVisible={true}
                        questions={[]}
                      />

                      {(quizQuetions && quizQuetions.length > 0 ) && (
                        <div>
                          <QuizQuestionList
                            from="Add"
                            onEditQuizQuestionHanlder=""
                            onDeletetQuizQuestionHanlder=""
                            questions={quizQuetions?.Question ? quizQuetions?.Question : []}
                            isActionBtnVisible={false}
                          />
                        </div>
                      )}

                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {props.t("Submit")}
                        </Button>{" "}
                        <Button color="secondary" onClick={e => formClear()}>
                          {props.t("Clear")}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CreatePostQuestion.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CreatePostQuestion)