import React, { useMemo, useEffect, useState } from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
} from "reactstrap"

// CK Editor
import Editor from "components/Common/Editor"
import constants from "config/constants"
// Form Editor

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToHTML } from "draft-convert"
import { EditorState, ContentState, convertFromHTML } from "draft-js"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { CMSApi } from "apis/CMSApi"
import { PageContentApi } from "apis/PageContentApi"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import Swal from "sweetalert2"
import { useHistory } from "react-router-dom"
import Select from "react-select"

const EditPageContent = props => {
  const history = useHistory()
  const page_content_id = props.match.params.page_content_id
  const [pageContent, setPageContent] = useState({})
  const [editorLoaded, setEditorLoaded] = useState(false)
  const [defaultType, setDefaultType] = useState()
  const [editorData, setEditorData] = useState("")

  
  //meta title
  document.title = props.t("Stuolio | Page Content")

  useEffect(() => {
    getPageById()
    setEditorLoaded(true)
  }, [])

  const getPageById = () => {
    let pageContentId = { page_content_id: page_content_id }
    PageContentApi.getPageContentById(pageContentId)
      .then(res => {
        setPageContent(res.data.data);
        setDefaultType({
          value : res.data?.data?.type,
          label : res.data?.data?.type,
        });
      })
      .catch(err => {
        console.log(err)
      })
  }

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: pageContent ? pageContent.name : "",
      type: pageContent ? pageContent.type : "",
      description: pageContent ? pageContent.description : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required(props.t("Required-Message")),
      type: Yup.string().required(props.t("Required-Message")),
      description: Yup.string().required(props.t("Required-Message")),
    }),
    onSubmit: values => {
      const data = new FormData()
      data.append("name", values.name)
      data.append("type", values.type)
      data.append(
        "description",
        editorData ? editorData : values.description
      )
      data.append("page_content_id", page_content_id)
      PageContentApi.updatePageContent(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result.isConfirmed) {
                history.push("/page-contents")
              }
            })
          } else {
            toastr.error(res.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Master")}
            breadcrumbItem={props.t("Edit") + " " + props.t("Page_Content")}
          />
          <Row>
            <Col className="col-12">
              <Button
                style={{ float: "right", marginBottom: "20px" }}
                color="primary"
                className="waves-effect waves-light  pull-right"
                onClick={() => history.goBack()}
              >
                {props.t("Back")}
              </Button>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    method="post"
                    className="wysiwyg-custom"
                    onSubmit={e => {
                      e.preventDefault()
                      validationType.handleSubmit()
                      return false
                    }}
                  >
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Name")}</Label>
                        <Input
                          name="name"
                          placeholder={props.t("Name")}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.name || ""}
                          invalid={
                            validationType.touched.name &&
                            validationType.errors.name
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.name &&
                        validationType.errors.name ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Type")}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          className={
                            validationType.touched.type &&
                            validationType.errors.type
                              ? "bor "
                              : "text-dark"
                          }
                          styles={{
                            control: styles => ({
                              ...styles,
                            }),
                          }}
                          onChange={e => {
                            validationType.setFieldValue("type", e.value)
                            setDefaultType(e);
                          }}
                          value={defaultType || ""}
                          onBlur={validationType.handleBlur}
                          name="type"
                          options={Object.keys(constants.PAGE_TYPES).map(
                            key => ({
                              value: `${key}`,
                              label: `${key}`,
                            })
                          )}
                          placeholder={<div>{props.t("Please_Select_Type")}</div>}
                        />
                        {validationType.touched.type &&
                        validationType.errors.type ? (
                          <div
                            style={{
                              color: "#f46a6a",
                              fontSize: "11px",
                              marginTop: "3px",
                            }}
                          >
                            {validationType.errors.type}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {props.t("Description")}
                        </Label>
                        <Editor
                          name="description"
                          onChange={data => {
                            setEditorData(data)
                          }}
                          editorLoaded={editorLoaded}
                          value={validationType.values.description || ""}
                        />
                        {validationType.touched.description &&
                        validationType.errors.description ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.description}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Button color="primary" type="submit">
                      {props.t("Update")}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditPageContent)
