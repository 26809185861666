import React, { useMemo, useEffect, useState } from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  FormFeedback,
  Button,
  Container,
} from "reactstrap"

// CK Editor
import Editor from "components/Common/Editor"
import constants from "config/constants"
// Form Editor

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToHTML } from "draft-convert"
import { EditorState, ContentState, convertFromHTML } from "draft-js"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { CMSApi } from "apis/CMSApi"
import { ApprovalRequestApi } from "apis/ApprovalRequestApi"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import Swal from "sweetalert2"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import _ from "lodash";

const EditApprovalRequest = props => {
  const history = useHistory()
  const request_id = props.match.params.request_id
  const [approvalRequest, setApprovalRequest] = useState({})
  const [defaultStatus, setDefaultStatus] = useState()
  const [customValidation, setCustomValidation] = useState({
    status: Yup.string().required(props.t("Required-Message"))
  });

  const onValidationHandler = (fieldName, value) => {
    const customValidationCopy = _.cloneDeep(customValidation);
    if (fieldName === "status") {
      if (value == constants.USER_STATUS.Rejected) {
        customValidationCopy.reason = Yup.mixed().required(props.t("Required-Message"));
      } else if (value == constants.USER_STATUS.Approved) {
        customValidationCopy.reason = undefined;
      } else {
        customValidationCopy.reason = undefined;
      }
    }
    setCustomValidation(customValidationCopy);
  };

  document.title = props.t("Edit-Pending-Request")

  useEffect(() => {
    getApprovalRequest();
  }, [])

  const getApprovalRequest = () => {
    let data = { request_id: request_id }
    ApprovalRequestApi.getApprovalRequest(data)
      .then(res => {
        setApprovalRequest(res.data.data)
        setDefaultStatus({
          value: res.data?.data?.status,
          label: res.data?.data?.status,
        })
      })
      .catch(err => {})
  }

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: approvalRequest ? approvalRequest.first_name : "",
      last_name: approvalRequest ? approvalRequest.last_name : "",
      email: approvalRequest ? approvalRequest.email : "",
      country_phone_code: approvalRequest ? approvalRequest.country_phone_code: "",
      phone_no: approvalRequest ? approvalRequest.phone_no : "",
      gender: approvalRequest ? approvalRequest.gender : "",
      address: approvalRequest ? approvalRequest.address : "",
      school_name: approvalRequest ? approvalRequest.school_name : "",
      student_id: approvalRequest ? approvalRequest.student_id : "",
      organization_name: approvalRequest ? approvalRequest.organization_name : "",
      website: approvalRequest ? approvalRequest.website : "",
      no_of_experience: approvalRequest ? approvalRequest.no_of_experience : "",
      about_me: approvalRequest ? approvalRequest.about_me : "",
      fees: approvalRequest ? approvalRequest.fees : "",
      reason: approvalRequest ? approvalRequest.reason : "",
    },

    validationSchema: Yup.object().shape({
      ...customValidation,
    }),
    onSubmit: values => {
      const data = new FormData()
      data.append("request_id", request_id)
      data.append("first_name", values.first_name)
      data.append("last_name", values.last_name)
      data.append("email", values.email)
      data.append("country_phone_code", values.country_phone_code)
      data.append("phone_no", values.phone_no)
      data.append("gender", values.gender)
      data.append("address", values.address)
      data.append("about_me", values.about_me)
      data.append("school_name", values.school_name)
      data.append("student_id", values.student_id)
      data.append("organization_name", values.organization_name)
      data.append("website", values.website)
      data.append("no_of_experience", values.no_of_experience)
      data.append("fees", values.fees)
      data.append("status", values.status)
      data.append("reason", values.reason)
      ApprovalRequestApi.updateApprovalRequest(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result.isConfirmed) {
                history.push("/approval-Requests")
              }
            })
          } else {
            toastr.error(res.message, "")
          }
        })
        .catch(function (error) {
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={props.t("Master")}
            breadcrumbItem={props.t("Edit") + " " + props.t("Consultant-Approval")}
          />
          <Row>
            <Col className="col-12">
              <Button
                style={{ float: "right", marginBottom: "20px" }}
                color="primary"
                className="waves-effect waves-light  pull-right"
                onClick={() => history.goBack()}
              >
                {props.t("Back")}
              </Button>
            </Col>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    method="post"
                    className="wysiwyg-custom"
                    onSubmit={e => {
                      e.preventDefault()
                      validationType.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("First_Name")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="first_name"
                            placeholder={props.t("First_Name")}
                            type="text"
                            readOnly="readOnly"
                            disabled={true}
                            onChange={validationType.handleChange}
                            //   editorLoaded={editorLoaded}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.first_name || ""}
                            invalid={
                              validationType.touched.first_name &&
                              validationType.errors.first_name
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.first_name &&
                          validationType.errors.first_name ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.first_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Last_Name")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="last_name"
                            placeholder={props.t("Last_Name")}
                            type="text"
                            readOnly="readOnly"
                            disabled={true}
                            onChange={validationType.handleChange}
                            //   editorLoaded={editorLoaded}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.last_name || ""}
                            invalid={
                              validationType.touched.last_name &&
                              validationType.errors.last_name
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.last_name &&
                          validationType.errors.last_name ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.last_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Email")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="email"
                            placeholder={props.t("Email")}
                            type="email"
                            readOnly="readOnly"
                            disabled={true}
                            onChange={validationType.handleChange}
                            //   editorLoaded={editorLoaded}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.email || ""}
                            invalid={
                              validationType.touched.email &&
                              validationType.errors.email
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.email &&
                          validationType.errors.email ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Country_Code")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="country_phone_code"
                            placeholder={props.t("Country_Code")}
                            type="text"
                            readOnly="readOnly"
                            disabled={true}
                            onChange={validationType.handleChange}
                            //   editorLoaded={editorLoaded}
                            onBlur={validationType.handleBlur}
                            value={
                              validationType.values.country_phone_code || ""
                            }
                            invalid={
                              validationType.touched.country_phone_code &&
                              validationType.errors.country_phone_code
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.country_phone_code &&
                          validationType.errors.country_phone_code ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.country_phone_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Phone_No")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="phone_no"
                            placeholder={props.t("Phone_No")}
                            type="text"
                            readOnly="readOnly"
                            disabled={true}
                            onChange={validationType.handleChange}
                            //   editorLoaded={editorLoaded}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.phone_no || ""}
                            invalid={
                              validationType.touched.phone_no &&
                              validationType.errors.phone_no
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.phone_no &&
                          validationType.errors.phone_no ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.phone_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Gender")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="gender"
                            placeholder={props.t("Gender")}
                            type="text"
                            readOnly="readOnly"
                            disabled={true}
                            onChange={validationType.handleChange}
                            //   editorLoaded={editorLoaded}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.gender || ""}
                            invalid={
                              validationType.touched.gender &&
                              validationType.errors.gender
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.gender &&
                          validationType.errors.gender ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.gender}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Address")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="address"
                            placeholder={props.t("Address")}
                            type="textarea"
                            readOnly="readOnly"
                            disabled={true}
                            onChange={validationType.handleChange}
                            //   editorLoaded={editorLoaded}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.address || ""}
                            invalid={
                              validationType.touched.address &&
                              validationType.errors.address
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.address &&
                          validationType.errors.address ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("About-Me")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="about_me"
                            placeholder={props.t("About-Me")}
                            type="textarea"
                            readOnly="readOnly"
                            disabled={true}
                            onChange={validationType.handleChange}
                            //   editorLoaded={editorLoaded}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.about_me || ""}
                            invalid={
                              validationType.touched.about_me &&
                              validationType.errors.about_me
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.about_me &&
                          validationType.errors.about_me ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.about_me}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    {approvalRequest?.Role?.id === constants.ROLE.STUDENT && (
                      <>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Student_Id")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                name="student_id"
                                placeholder={props.t("Student_Id")}
                                type="text"
                                readOnly="readOnly"
                                disabled={true}
                                onChange={validationType.handleChange}
                                //   editorLoaded={editorLoaded}
                                onBlur={validationType.handleBlur}
                                value={validationType.values.student_id || ""}
                                invalid={
                                  validationType.touched.student_id &&
                                  validationType.errors.student_id
                                    ? true
                                    : false
                                }
                              />
                              {validationType.touched.student_id &&
                              validationType.errors.student_id ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.student_id}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("School-Name")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                name="school_name"
                                placeholder={props.t("School-Name")}
                                type="text"
                                readOnly="readOnly"
                                disabled={true}
                                onChange={validationType.handleChange}
                                //   editorLoaded={editorLoaded}
                                onBlur={validationType.handleBlur}
                                value={validationType.values.school_name || ""}
                                invalid={
                                  validationType.touched.school_name &&
                                  validationType.errors.school_name
                                    ? true
                                    : false
                                }
                              />
                              {validationType.touched.school_name &&
                              validationType.errors.school_name ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.school_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}

                    {approvalRequest?.Role?.id === constants.ROLE.CONSULTANT && (
                      <>
                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Organization-Name")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                name="organization_name"
                                placeholder={props.t("Organization-Name")}
                                type="text"
                                readOnly="readOnly"
                                disabled={true}
                                onChange={validationType.handleChange}
                                //   editorLoaded={editorLoaded}
                                onBlur={validationType.handleBlur}
                                value={
                                  validationType.values.organization_name || ""
                                }
                                invalid={
                                  validationType.touched.organization_name &&
                                  validationType.errors.organization_name
                                    ? true
                                    : false
                                }
                              />
                              {validationType.touched.organization_name &&
                              validationType.errors.organization_name ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.organization_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Years-Of-Experience")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                name="no_of_experience"
                                placeholder={props.t("Years-Of-Experience")}
                                type="text"
                                readOnly="readOnly"
                                disabled={true}
                                onChange={validationType.handleChange}
                                //   editorLoaded={editorLoaded}
                                onBlur={validationType.handleBlur}
                                value={validationType.values.no_of_experience || ""}
                                invalid={
                                  validationType.touched.no_of_experience &&
                                  validationType.errors.no_of_experience
                                    ? true
                                    : false
                                }
                              />
                              {validationType.touched.no_of_experience &&
                              validationType.errors.no_of_experience ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.no_of_experience}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Fees")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                name="fees"
                                placeholder={props.t("Fees")}
                                type="text"
                                readOnly="readOnly"
                                disabled={true}
                                onChange={validationType.handleChange}
                                //   editorLoaded={editorLoaded}
                                onBlur={validationType.handleBlur}
                                value={validationType.values.fees || ""}
                                invalid={
                                  validationType.touched.fees &&
                                  validationType.errors.fees
                                    ? true
                                    : false
                                }
                              />
                              {validationType.touched.fees &&
                              validationType.errors.fees ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.fees}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {props.t("Website")}{" "}
                                {/* <span className="text-danger">*</span> */}
                              </Label>
                              <Input
                                name="website"
                                placeholder={props.t("Website")}
                                type="text"
                                readOnly="readOnly"
                                disabled={true}
                                onChange={validationType.handleChange}
                                //   editorLoaded={editorLoaded}
                                onBlur={validationType.handleBlur}
                                value={validationType.values.website || ""}
                                invalid={
                                  validationType.touched.website &&
                                  validationType.errors.website
                                    ? true
                                    : false
                                }
                              />
                              {validationType.touched.website &&
                              validationType.errors.website ? (
                                <FormFeedback type="invalid">
                                  {validationType.errors.website}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Status")}{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            className={
                              validationType.touched.type &&
                              validationType.errors.type
                                ? "bor "
                                : "text-dark"
                            }
                            styles={{
                              control: styles => ({
                                ...styles,
                              }),
                            }}
                            onChange={e => {
                              validationType.setFieldValue("status", e.value)
                              setDefaultStatus(e)
                              onValidationHandler("status", e?.value);
                            }}
                            value={defaultStatus || ""}
                            onBlur={validationType.handleBlur}
                            name="status"
                            options={Object.keys(constants.USER_STATUS).map(
                              value => ({
                                value: `${value}`,
                                label: `${value}`,
                              })
                            )}
                            placeholder={<div>{props.t("Select_Status")}</div>}
                          />
                          {validationType.touched.status &&
                          validationType.errors.status ? (
                            <div
                              style={{
                                color: "#f46a6a",
                                fontSize: "11px",
                                marginTop: "3px",
                              }}
                            >
                              {validationType.errors.status}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Reason")}{" "}
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="reason"
                            placeholder={props.t("Reason")}
                            type="textarea"
                            //   readOnly="readOnly"
                            //   disabled={true}
                            onChange={validationType.handleChange}
                            //   editorLoaded={editorLoaded}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.reason || ""}
                            invalid={
                              validationType.touched.reason &&
                              validationType.errors.reason
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.reason &&
                          validationType.errors.reason ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.reason}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Button color="primary" type="submit">
                      {props.t("Update")}
                    </Button>

                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditApprovalRequest)
