import React, { useEffect, useState } from "react"
import Select from "react-select"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  CardImg,
  CardText,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import Loader from "components/Common/Loader"
import Swal from "sweetalert2"
import { withTranslation } from "react-i18next"
import constants from "../../config/constants"
import config from "config/config"
import { PostApi } from "apis/PostApi"
import { CommanApi } from "apis/CommanApi";
import { Editor } from "react-draft-wysiwyg"
import { EditorState } from "draft-js"
import { convertEditorContentTextToHtml } from "helpers/common_helper"
import _ from "lodash";

const CreatePost = props => {
  const [defaultType, setDefaultType] = useState();
  const [defaultVisibleTo, setDefaultVisibleTo] = useState();
  const [maxPostChar, setMaxPostChar] = useState();
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [messageContent, setMessageContent] = useState(() => EditorState.createEmpty());
  const [customValidation, setCustomValidation] = useState({
    title: Yup.string().required(props.t("Required-Message")),
    type: Yup.string().required(props.t("Required-Message")),
    visible_to: Yup.string().required(props.t("Required-Message")),
    message : Yup.mixed().required(props.t("Required-Message"))
  });

  const getSystemSetting = () => {
    const payload = {
      config_key: "max_post_char",
    };
    CommanApi.getSystemSetting(payload)
      .then((res) => {
        if (res.data.success) {
          setMaxPostChar(res.data?.data?.config_value);
        } else {
          setMaxPostChar(constants.POST_MESSAGE_CHAR_LIMIT);
        }
      }).catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSystemSetting();
  }, []);
  
  useEffect(() => {
    setDefaultType({
      value : constants.POST_TYPES["AdminPost"],
      label : constants.POST_TYPES["AdminPost"]
    })
    setDefaultVisibleTo({
      value : constants.VISIBLE_TO["All"],
      label : constants.VISIBLE_TO["All"]
    })
  }, []);

  const onValidationHandler = (fieldName, value) => {
    const customValidationCopy = _.cloneDeep(customValidation);
    if (fieldName === "type") {
      if (value == constants.POST_TYPES.AdminPost) {
        customValidationCopy.message = Yup.mixed().required(props.t("Required-Message"));
        customValidationCopy.post_media_files = undefined;
      } else if (value == constants.POST_TYPES.AdminReel) {
        customValidationCopy.post_media_files = Yup.mixed().test(
          "fileSize",
          props.t("Please-Select-Only-One-File"),
          (value) => {
            if (value && value?.length === 1 && value[0].size <= constants.FILE_VALIDATION.POST_REEL) {
              return true;
            }
            return false;
          }
        );
        customValidationCopy.message = undefined;
      } else {
          customValidationCopy.post_media_files = Yup.mixed()
            .test(
              "fileSize",
              props.t("File_Size_is_too_large") + "Max Size is 2 MB",
              value => {
                if (value && value?.length > 0) {
                  for (let i = 0; i < value.length; i++) {
                    if (value[i].size > constants.IMAGE_VALIDATION.POST_IMAGE) {
                      return false
                    }
                  }
                }
              return true
            })
            .test("fileType", props.t("Unsupported_File_Format"), value => {
              if (value && value.length > 0) {
                for (let i = 0; i < value.length; i++) {
                  if ( value[i].type != "image/png" && value[i].type != "image/jpg" && value[i].type != "image/jpeg") {
                    return false
                  }
                }
              }
            return true
          });
        customValidationCopy.message = undefined;
      }
    }
    setCustomValidation(customValidationCopy);
  };

  const handleTextareaChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= maxPostChar) {
      validationType.setFieldValue('message', newText);
    }
  };
  
  // Form validation
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: "",
      title: "",
      type: constants.POST_TYPES.AdminPost,
      visible_to : constants.VISIBLE_TO.All,
      post_media_files: null,
    },
    validationSchema: Yup.object().shape({
      ...customValidation,
    }),
    onSubmit: values => {
      setLoading(true);
      const data = new FormData()
      if (values.post_media_files != null) {
        for (let i = 0; i < values.post_media_files.length; i++) {
          data.append("post_media_files", values.post_media_files[i])
        }
      }
      data.append("title", values.title);
      data.append("type", values.type);
      data.append("visible_to", values.visible_to);
      data.append("message", values.message);
      PostApi.createPost(data)
        .then(res => {
          if (res.data.success) {
            Swal.fire({
              text: res.data.message,
              icon: "success",
              imageAlt: "success image",
            }).then(result => {
              if (result?.isConfirmed) {
                history.push("/posts");
                formClear();
                setDefaultType({
                  value : constants.POST_TYPES["AdminPost"],
                  label : constants.POST_TYPES["AdminPost"]
                });
                setDefaultVisibleTo({
                  value : constants.VISIBLE_TO["All"],
                  label : constants.VISIBLE_TO["All"]
                })
                setLoading(false);
              }
            })
          } else {
            toastr.error(res?.data?.message, "")
          }
        })
        .catch(function (error) {
          setLoading(false);
          if (error?.response?.data?.message) {
            toastr.error(error?.response?.data?.message, "")
          } else {
            toastr.error(
              error?.response?.data?.error?.errors?.map(
                (item, v) => `${item.msg}<br>`
              ),
              ""
            )
          }
        })
    },
  })

  const formClear = () => {
    validationType.resetForm();
    setDefaultType({
      value : constants.POST_TYPES["AdminPost"],
      label : constants.POST_TYPES["AdminPost"]
    });
    setDefaultVisibleTo({
      value : constants.VISIBLE_TO["All"],
      label : constants.VISIBLE_TO["All"]
    });
  }

  const removeImage = lastModified => {
    const updatedList = [
      ...validationType.values.post_media_files.filter(
        e => e.lastModified !== lastModified
      ),
    ]
    validationType.setFieldValue(
      "post_media_files",
      updatedList.length > 0 ? updatedList : null
    )
  }

  const onChangeMessageContentHandler = async (state) => {
    setMessageContent(state)
    const contentHtml = await convertEditorContentTextToHtml(state);
    validationType.setFieldValue("message", contentHtml)
  }

  document.title = props.t("Posts") + " | " + props.t("Stuolio")
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs
          title={props.t("Posts")}
          breadcrumbItem={props.t("Add") + " " + props.t("Post")}
        />
        {loading ? (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '60vh', // Adjust as needed
              }}
            >
              <Loader></Loader>
            </div>
          </>
        ) : 
          <Container fluid={true}>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Col sm={12}>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validationType.handleSubmit()
                          return false
                        }}
                      >
                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Title")}{" "} <span className="text-danger">*</span></Label>
                            <Input
                              name="title"
                              placeholder={props.t("Title")}
                              type="text"
                              onChange={validationType.handleChange}
                              onBlur={validationType.handleBlur}
                              value={validationType.values.title || ""}
                              invalid={
                                validationType.touched.title && validationType.errors.title ? true : false
                              }
                            />
                            {validationType.touched.title && validationType.errors.title ? (
                              <FormFeedback type="invalid">{validationType.errors.title}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Type")}
                              {" "} <span className="text-danger">*</span></Label>
                            <Select
                            className={
                              validationType.touched.type &&
                              validationType.errors.type
                                ? "bor "
                                : "text-dark"
                            }
                            styles={{
                              control: styles => ({
                                ...styles,
                              }),
                            }}
                            onChange={e => {
                              validationType.setFieldValue("type", e.value);
                              setDefaultType(e);
                              onValidationHandler("type", e?.value);
                              if(e.value === constants.POST_TYPES.AdminReel) {
                                validationType.setFieldValue("message", "");
                              } 
                            }}
                            value={defaultType || ""}
                            onBlur={validationType.handleBlur}
                            name="type"
                            options={Object.keys(constants.POST_TYPES).map(
                              value => ({
                                value: value,
                                label: constants.POST_TYPES[value]
                              })
                            )}
                            placeholder={
                              props.t("Select_Type")
                            }
                          />
                          {validationType.touched.type && validationType.errors.type ? (
                            <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                              {validationType.errors.type}
                            </div>
                          ) : null}
                        </div>
                        </Col>

                        <Col sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Visible_To")}
                              {" "} <span className="text-danger">*</span></Label>
                            <Select
                            className={
                              validationType.touched.visible_to &&
                              validationType.errors.visible_to
                                ? "bor "
                                : "text-dark"
                            }
                            styles={{
                              control: styles => ({
                                ...styles,
                              }),
                            }}
                            onChange={e => {
                              validationType.setFieldValue("visible_to", e.value);
                              setDefaultVisibleTo(e);
                            }}
                            value={defaultVisibleTo || ""}
                            onBlur={validationType.handleBlur}
                            name="type"
                            options={Object.keys(constants.VISIBLE_TO).map(
                              value => ({
                                value: value,
                                label: constants.VISIBLE_TO[value]
                              })
                            )}
                            placeholder={
                              props.t("Select_Visible_To")
                            }
                          />
                          {validationType.touched.visible_to && validationType.errors.visible_to ? (
                            <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                              {validationType.errors.visible_to}
                            </div>
                            ) : null}
                          </div>
                        </Col>

                        {defaultType?.value == constants.POST_TYPES.AdminPost ? (
                          // <Col sm={12}>
                          //   <div className="mb-3">
                          //     <Label className="form-label">
                          //       {props.t("Message")}
                          //       {" "}<span className="text-danger">*</span>
                          //     </Label>
                          //     <Editor
                          //       name="message"
                          //       editorState={messageContent}
                          //       onEditorStateChange={onChangeMessageContentHandler}
                          //       toolbarClassName="toolbarClassName"
                          //       wrapperClassName="wrapperClassName"
                          //       editorClassName="editorClassName"
                          //       styles={{
                          //         control: (provided, state) => ({
                          //           ...provided,
                          //           borderColor:
                          //             validationType.touched.message && validationType.touched.message && validationType.touched.message &&
                          //             validationType.errors.message
                          //               ? "#f46a6a"
                          //               : "#ccc",
                          //         }),
                          //       }}
                          //     />
                          //     {validationType.touched.message &&
                          //     validationType.errors.message ? (
                          //       <FormFeedback type="invalid">
                          //         {validationType.errors.message}
                          //       </FormFeedback>
                          //     ) : null}
                          //   </div>
                          // </Col>
                          <Col sm={12}>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Message")}{" "} <span className="text-danger">*</span></Label>
                              <Input
                                name="message"
                                placeholder={props.t("Message")}
                                type="textarea"
                                rows={10}
                                // onChange={validationType.handleChange}
                                onChange={handleTextareaChange}
                                onBlur={validationType.handleBlur}
                                value={validationType.values.message || ""}
                                invalid={
                                  validationType.touched.message && validationType.errors.message ? true : false
                                }
                              />
                              {validationType.touched.message && validationType.errors.message ? (
                                <FormFeedback type="invalid">{validationType.errors.message}</FormFeedback>
                              ) : null}
                              <div style={{ color: "rgb(244, 106, 106)", fontSize: "11px", marginTop: "3px"}}>
                                Characters left: {maxPostChar - validationType.values.message.length}  
                              </div>
                            </div>
                          </Col>
                          ) : null
                        }
                        {defaultType?.value == constants.POST_TYPES.AdminReel ? (
                          <Col sm={12}>
                            <div className="mb-3">
                              <Label htmlFor="validationCustom01">
                                {props.t("Post_Media_Files")}
                                <span className="text-danger">*</span>
                              </Label>
                              <span className="text-danger">
                                {" "}
                                ({props.t("Size")} :{" "}
                                {props.t("10MB")})
                              </span>
                              <Input
                                name="post_media_files"
                                type="file"
                                accept=".mp4,.WEBM,.MPEG,.AVI,.WMV,.MOV,.FLV"
                                multiple
                                className="form-control"
                                onBlur={e => {
                                  if (
                                    e.target.files.length == 0 &&
                                    validationType.values.post_media_files &&
                                    validationType.values.post_media_files.length == 0
                                  ) {
                                    validationType.setFieldValue(
                                      "post_media_files",
                                      null
                                    )
                                  }
                                }}
                                onChange={e => {
                                  const filesArray2 = []
                                  for (let i = 0; i < e.target.files.length; i++) {
                                    filesArray2.push(e.target.files[i])
                                  }
                                  validationType.setFieldValue(
                                    "post_media_files",
                                    validationType.values.post_media_files
                                      ? [
                                          ...validationType.values.post_media_files,
                                          ...filesArray2,
                                        ]
                                      : filesArray2
                                  )
                                }}
                                invalid={
                                  validationType.touched.post_media_files &&
                                  validationType.errors.post_media_files
                                    ? true
                                    : false
                                }
                              />
                              {validationType.touched.post_media_files &&
                              validationType.errors.post_media_files ? (
                                <div
                                  style={{
                                    color: "#f46a6a",
                                    fontSize: "11px",
                                    marginTop: "3px",
                                  }}
                                >
                                  {validationType.errors.post_media_files}
                                </div>
                              ) : null}
                            </div>
                            <div className="d-flex px-3 py-2 pb-4">
                              {validationType.values.post_media_files?.length > 0
                                ? validationType.values.post_media_files?.map(
                                    (item, index) => {
                                      return (
                                        <Col sm={1} key={index}>
                                          {item?.type === "video/mp4" ?
                                            <CardImg
                                              src="assets/images/videofile.png"
                                              alt="Reel"
                                              className="rounded avatar-sm"
                                            />
                                          : 
                                            <CardImg
                                              src={item ? URL.createObjectURL(item) : ""}
                                              alt="Post"
                                              className="rounded avatar-sm"
                                            />
                                          }
                                          <CardText style={{marginRight:"8px"}}>
                                            <a
                                              className="text-danger"
                                              type="button"
                                              onClick={() => {
                                                removeImage(item.lastModified)
                                              }}
                                            >
                                              {props.t("Remove")}
                                            </a>
                                          </CardText>
                                        </Col>
                                      )
                                    }
                                  )
                                : null}
                            </div>
                          </Col>
                          ) : null
                        }
                        <div className="d-flex flex-wrap gap-2">
                          <Button type="submit" color="primary">
                            {props.t("Submit")}
                          </Button>{" "}
                          <Button color="secondary" onClick={e => formClear()}>
                            {props.t("Clear")}
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
    </React.Fragment>
  )
}

CreatePost.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CreatePost)
