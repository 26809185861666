// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { CityApi } from "apis/CityApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";
import Select from 'react-select'

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";
import { StatesApi } from "apis/StatesApi";

function City(props) {
    const history = useHistory();
    const [cityList, setCityList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [city, setCity] = useState([]);
    const [postCityId, setCityId] = useState('');
    const [defaultState, setDefaultState] = useState(0);
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    useEffect(() => {
        getAllCity(activeData);
    }, []);

    const formClear = () => {
        validationType.resetForm();
        setCity();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.city_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Country"),
            selector: row => row.country,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("State"),
            selector: row => row.state,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name"),
            selector: row => row.name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getAllCity = (data) => {
        setLoading(true);
        CityApi.getCities(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        city_id: sr_no,
                        country: v.State?.Country?.name,
                        state: v.State?.name,
                        name: v.name,
                        action: (
                            <>

                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setCityList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });

        StatesApi.getAllStates()
            .then((res) => {
                setStatesList(res.data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let cityId = { "city_id": id };
                CityApi.deleteCity(cityId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        setCityId();
                        formClear();
                        getAllCity(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (id) => {
        let cityId = { "city_id": id };
        setCityId(id);
        CityApi.getCity(cityId)
            .then((res) => {
                setCity(res.data.data);
                setDefaultState({
                    value : res.data?.data?.State?.id,
                    label : res.data?.data?.State?.name
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Form validation 
    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            state_id: city ? city.state_id : "",
            city_id: city ? city.id : "",
            name: city ? city.name : "",
        },
        validationSchema: Yup.object().shape({
            state_id: Yup.string().required(
                props.t("Required-Message")
            ),
            name: Yup.string().required(
                props.t("Required-Message")
            )
        }),
        onSubmit: (values) => {
            if (postCityId) {
                values.city_id = postCityId;
                CityApi.updateCity(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/cities');
                                    setCityId();
                                    formClear();
                                    setDefaultState();
                                    getAllCity(activeData)
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }
            else {
                CityApi.createCity(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/cities');
                                    formClear();
                                    setDefaultState();
                                    getAllCity(activeData);
                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }

        }
    });

    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getAllCity(data)
    }

    const handleRowChange = (v) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllCity(data)
    }

    //meta title
    document.title = props.t("City") + ' | ' + props.t("Stuolio");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Cities")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        progressPending={loading}
                                        columns={columns}
                                        data={cityList}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={activeData.totalPage}
                                        paginationPerPage={activeData.limit}
                                        defaultSortFieldID={1}
                                        onChangeRowsPerPage={value => handleRowChange(value)}
                                        onChangePage={value => handleChange(value)}
                                        sortable
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        highlightOnHover
                                    />
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="name"
                                                    placeholder={props.t("Name")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name || ""}
                                                    invalid={
                                                        validationType.touched.name && validationType.errors.name ? true : false
                                                    }
                                                />
                                                {validationType.touched.name && validationType.errors.name ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("State")}{" "} <span className="text-danger">*</span></Label>
                                                <Select
                                                    className={
                                                        validationType.touched.type &&
                                                        validationType.errors.type
                                                            ? "bor "
                                                            : "text-dark"
                                                    }
                                                    styles={{
                                                        control: styles => ({
                                                            ...styles,
                                                        }),
                                                    }}
                                                    onChange={e => {
                                                        validationType.setFieldValue("state_id", e.value)
                                                        setDefaultState(e)
                                                    }}
                                                    value={defaultState || ""}
                                                    onBlur={validationType.handleBlur}
                                                    name="state_id"
                                                    options={statesList.rows?.map(
                                                        value => ({
                                                            value: `${value.id}`,
                                                            label: `${value.name}`,
                                                        })
                                                    )}
                                                    placeholder={
                                                        <div>{props.t("Please_Select_State")}</div>
                                                    }
                                                />
                                                {validationType.touched.state_id && validationType.errors.state_id ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.state_id}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

City.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,
};
export default withTranslation()(City);