// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';

import { InterestApi } from "apis/InterestApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";
import Select from "react-select";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function Interest(props) {
    const history = useHistory();
    const [interestList, setInterestList] = useState([]);
    const [interests, setInterest] = useState([]);
    const [interestId, setInterestId] = useState('');
    const [loading, setLoading] = useState(false);
    const [defaultStatus, setDefaultStatus] = useState();

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    useEffect(() => {
        getInterests(activeData);
        setDefaultStatus({
            value : constants.STATUS[1],
            label : constants.STATUS[1]
        })
    }, []);

    const formClear = () => {
        validationType.resetForm();
        setInterest();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.interest_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name"),
            selector: row => row.name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Status"),
            selector: row => row.status,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getInterests = (data) => {
        setLoading(true);
        InterestApi.getInterests(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        interest_id: sr_no,
                        name: v.name,
                        status: v.status == 1 ? (
                            <>
                                <span style={{ color: "green" }}>{props.t("Active")}</span>
                            </>
                        ) : (
                            <>
                                <span style={{ color: "red" }}>{props.t("Deactive")}</span>
                            </>
                        ),
                        action: (
                            <>

                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setInterestList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const onDelete = (id) => {
        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let interestId = { "interest_id": id };
                InterestApi.deleteInterest(interestId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        setInterestId();
                        formClear()
                        getInterests(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (id) => {
        let interestId = { "interest_id": id };
        setInterestId(id);
        InterestApi.getInterest(interestId)
            .then((res) => {
                setInterest(res.data.data);
                setDefaultStatus({
                    value : res.data?.data?.status,
                    label : constants.STATUS[res.data?.data?.status]
                    
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Form validation 
    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            interest_id: interests ? interests.id : "",
            name: interests ? interests.name : "",
            status: interests ? interests.status : true,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                props.t("Required-Message")
            )
        }),
        onSubmit: (values) => {
            if (interestId) {
                values.interest_id = interestId;
                InterestApi.updateInterest(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/interests');
                                    formClear();
                                    getInterests(activeData);
                                    setDefaultStatus({
                                        value : constants.STATUS[1],
                                        label : constants.STATUS[1]
                                    });
                                    setInterestId();
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }
            else {
                InterestApi.createInterest(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/interests');
                                    formClear();
                                    getInterests(activeData);
                                    setDefaultStatus({
                                        value : constants.STATUS[1],
                                        label : constants.STATUS[1]
                                    });
                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }

        }
    });

    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getInterests(data)
    }

    const handleRowChange = (v) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getInterests(data)
    }

    //meta title
    document.title = props.t("Interests") + ' | ' + props.t("Stuolio");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Interests")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        progressPending={loading}
                                        columns={columns}
                                        data={interestList}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={activeData.totalPage}
                                        paginationPerPage={activeData.limit}
                                        defaultSortFieldID={1}
                                        onChangeRowsPerPage={value => handleRowChange(value)}
                                        onChangePage={value => handleChange(value)}
                                        sortable
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        highlightOnHover
                                    />
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="name"
                                                    placeholder={props.t("Name")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name || ""}
                                                    invalid={
                                                        validationType.touched.name && validationType.errors.name ? true : false
                                                    }
                                                />
                                                {validationType.touched.name && validationType.errors.name ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{props.t("Status")}
                                                        {" "} <span className="text-danger">*</span></Label>
                                                    <Select
                                                        className={
                                                            validationType.touched.type &&
                                                            validationType.errors.type
                                                                ? "bor "
                                                                : "text-dark"
                                                        }
                                                        styles={{
                                                            control: styles => ({
                                                                ...styles,
                                                            }),
                                                        }}
                                                        onChange={e => {
                                                            validationType.setFieldValue("status", e.value)
                                                            setDefaultStatus(e)
                                                        }}
                                                        value={defaultStatus || ""}
                                                        onBlur={validationType.handleBlur}
                                                        name="status"
                                                        options={Object.keys(constants.STATUS).map(
                                                            value => ({
                                                                value: `${value}`,
                                                                label: `${constants.STATUS[value]}`
                                                            })
                                                        )}
                                                        placeholder={
                                                            <div>{props.t("Select_Status")}</div>
                                                        }
                                                    />
                                                    {validationType.touched.status && validationType.errors.status ? (
                                                        <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                            {validationType.errors.status}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

Interest.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(Interest);