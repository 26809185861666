// src/components/filter.
import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"

//import components
import DataTable from "react-data-table-component"
import { Link } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import { ApprovalRequestApi } from "apis/ApprovalRequestApi"
import TableContainer from "components/Common/TableContainer"
import { withTranslation } from "react-i18next"
import config from "config/config"
import Swal from "sweetalert2"

function ApprovalRequest(props) {
  const [loading, setLoading] = useState(false)
  const [pendingRequestList, setPendingRequestList] = useState([])
  const [activeData, setActiveData] = useState({
    page: 1,
    totalPage: 1,
    search: "",
    limit: config.LIMIT,
  })
  useEffect(() => {
    getAllApprovalRequests(activeData)
  }, [])

  const getAllApprovalRequests = data => {
    // setLoading(true);
    ApprovalRequestApi.getApprovalRequests(data)
      .then(res => {
        setActiveData({
          activePage: activeData.page,
          totalPage: res.data.data.count,
          search: activeData.search,
          limit: activeData.limit,
        })
        let data = []
        var sr_no = res.data.sr_no_start
        res.data.data.rows.forEach((v, i) => {
          sr_no = sr_no + 1
          data[i] = {
            id: sr_no,
            first_name: v.first_name +" "+ v.last_name,
            email: v.email,
            phone_no: (v.phone_no !== null) ? "+"+v.country_phone_code+" "+v.phone_no : "-",
            role: v.Role.role,
            status:
              v.status == "Pending" ? (
                <>
                  <span style={{ color: "orange" }}>{props.t("Pending")}</span>
                </>
              ) : (
                <>
                  <span style={{ color: "red" }}>{props.t("Rejected")}</span>
                </>
              ),
            action: (
              <>
                <Link
                  style={{ marginRight: "15px" }}
                  to={`/edit-approval-Request/${v.id}`}
                >
                  <i className=" fas fa-edit" id="edittooltip" />
                </Link>
                {/* <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: "15px", color: "#7b8190" }}
                  href={`${config.API_URL}/api/common/pages/cms-page?page_type=${v.type}`}
                >
                  <i
                    className="mdi mdi-eye-outline font-size-18"
                    style={{ color: "#7b8190" }}
                    id="edittooltip"
                  />
                </a>
                <i
                  className="mdi mdi-delete font-size-18"
                  style={{ color: "red" }}
                  id="deletetooltip"
                  onClick={() => onDelete(v.id)}
                /> */}
              </>
            ),
          }
        })
        setPendingRequestList(data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const columns = [
    {
      name: props.t("Sr_No"),
      selector: row => row.id,
      sort: "asc",
      width: 150,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Consultant-Name"),
      selector: row => row.first_name,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Email ID"),
      selector: row => row.email,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Phone No."),
      selector: row => row.phone_no,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Role"),
      selector: row => row.role,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Status"),
      selector: row => row.status,
      sort: "asc",
      width: 270,
      sortable: true,
      defaultSortField: true,
      defaultSortAsc: false,
    },
    {
      name: props.t("Action"),
      selector: row => row.action,
      sort: "asc",
      width: 200,
    },
  ];

  const handleChange = v => {
    setActiveData({
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    })
    const data = {
      activePage: v,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: activeData.limit,
    }
    getAllApprovalRequests(data)
  }

  const handleRowChange = v => {
    setActiveData({
      activePage: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    })
    const data = {
      activePage: activeData.page,
      totalPage: activeData.totalPage,
      search: activeData.search,
      limit: v,
    }
    getAllApprovalRequests(data)
  }

  const onDelete = id => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let id = { id: id }
        ApprovalRequestApi.deletePendingRequest(id)
          .then(res => {
            Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"))
            getAllApprovalRequests(activeData)
          })
          .catch(err => {
            console.log(err)
          })
      }
    })
  }

  document.title = "Consultant Approvals | Stuolio"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={props.t("Stuolio")}
          breadcrumbItem={props.t("Consultant-Approvals")}
        />
      </div>

      <div className="container-fluid">
        <DataTable
          className="table-bordered"
          progressPending={loading}
          columns={columns}
          data={pendingRequestList}
          pagination
          paginationServer
          paginationTotalRows={activeData.totalPage}
          paginationPerPage={activeData.limit}
          defaultSortFieldID={1}
          onChangeRowsPerPage={value => handleRowChange(value)}
          onChangePage={value => handleChange(value)}
          sortable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          highlightOnHover
        />
      </div>
    </div>
  )
}

ApprovalRequest.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default withTranslation()(ApprovalRequest);