import React from 'react';
import { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

const LoaderComp = () => {
    let [color, setColor] = useState("#36d7b7");
    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red", // Customize the color if needed
    };
    return (
        <BeatLoader
            color={color}
            loading
            cssOverride={override}
            size={20}
            aria-label="Loading Spinner"
        />
    );
}

export default LoaderComp;