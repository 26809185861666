module.exports = {
  // API_URL: "https://api.stuolio.ai",
  API_URL: "https://api.stuoliodev.ilserver.cloud",
  // API_URL: "https://api.prod.stuolio.ai",
  // API_URL: "http://localhost:8002",
  LIMIT: 10,
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
}