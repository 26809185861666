import _ from "lodash";
import QuizQuestionList from "pages/Components/QuizQuestionList";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import "toastr/build/toastr.min.css";
import Swal from "sweetalert2"

const CreateUpdateQuestionModal = (props) => {
  const [postQuestions, setPostQuestions] = useState([]);
  const [formValues, setFormValues] = useState([
    { option: "", is_answer: false },
    { option: "", is_answer: false },
  ]);
  const [optionData, setOptionData] = useState([]);
  const [validateError, setValidateError] = React.useState({
    question: "",
    points: "",
    optionsErr: "",
  });
  const [question, setQuestion] = useState("");
  const [points, setPoints] = useState("");
  const [isAnsweredRadio, setIsAnsweredRadio] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const [isAddedAnyQuestion, setIsAddedAnyQuestion] = useState(false);
  const [currentQueIndex, setCurrentQueIndex] = useState();

  useEffect(() => {
    if (props.questions.length > 0) {
      setPostQuestions(props.questions);
    }
  }, [props.questions]);
  
  useEffect(() => {
    if (props?.selectedPostType) {
      setFormValues([
        { option: "", is_answer: false },
        { option: "", is_answer: false },
      ]);
      setValidateError({
        question: "",
        points: "",
        optionsErr: "",
      });
    }
  }, [props?.selectedPostType]);

  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      { option: "", is_answer: "" },
    ]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const onRemoveOptionHandleChange = (index) => {
    const optionDataCopy = _.cloneDeep(optionData);
    optionDataCopy.splice(index, 1);
    setOptionData(optionDataCopy);
  };

  const onHandleChange = (value, type, index) => {
    const optionDataCopy = _.cloneDeep(optionData);
    if (type === "option") {
      const editOptionData = optionDataCopy[index];
      if (!editOptionData) {
        const data = {
          option: value,
          is_answer: false,
        };
        optionDataCopy.push(data);
      } else {
        editOptionData.option = value;
        optionDataCopy.splice(index, 1, editOptionData);
      }
    } else if (type === "isAnswer") {
      const editOptionData = optionDataCopy[index];
      if (!editOptionData) {
        const data = {
          option: "",
          is_answer: value ? true : false,
        };
        optionDataCopy.push(data);
      } else {
        optionDataCopy.forEach((item) => {
          item.is_answer = false;
        });
        editOptionData.is_answer = value ? true : false;
        optionDataCopy.splice(index, 1, editOptionData);
      }
      setIsAnsweredRadio(true);
    }
    if (questionId != "") {
      setFormValues(optionDataCopy);
    }
    setOptionData(optionDataCopy);
  };

  const onSubmitQustionAndAnswer = async () => {
    let errors = await checkFormValidation(true);
    let errorsSize = Object.keys(errors).length;
    if (errorsSize != 0) {
      return false;
    }
    let questionArr = [];
    questionArr.push({
      question: question,
      points: points,
      Option: optionData,
    });
    setPostQuestions([...postQuestions, ...questionArr]);
    props.setQuizQuestions([...postQuestions, ...questionArr]);
    props.setAddedQuizCout([...postQuestions, ...questionArr].length);
    setIsSuccess(true);
    setSuccessMsg("Question Added Successfully!");
    setIsAddedAnyQuestion(true);
    setQuestion("");
    setPoints("");
    setIsAnsweredRadio(false);
    window.$("#is_answer").attr("checked", false);
    window.$("#option").val("");
    setOptionData([]);
    setFormValues([{ option: "", is_answer: false },{ option: "", is_answer: false }]);
    setValidateError({
      question: "",
      points: "",
      optionsErr: "",
    });
  };

  const checkFormValidation = async (check) => {
    if (check) {
      setIsSuccess(false);
      let ValidateError = {};
      if (question === "") {
        ValidateError.question = props.t("questionIsRequired");
      }
      if (points === "") {
        ValidateError.points = props.t("pointsIsRequired");
      }
      if (optionData.length <= 1) {
        ValidateError.optionsErr = props.t("quizOptionErrorMsg");
      }
      if (optionData.length >= 2 && !isAnsweredRadio) {
        ValidateError.optionsErr = props.t("quizOptionAnswerErrorMsg");
      }
      // if(!isUpdate){
      //   if(postQuestions.length >= props.maxQuestion || props.questions.length >= props.maxQuestion){
      //     ValidateError.optionsErr = props.t("quizMaxQuestionErrorMsg");
      //   }
      // }
      setValidateError(ValidateError);
      return ValidateError;
    } else {
      return {};
    }
  };

  const inputChange = (e) => {
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      let value = e.target.value;
      switch (e.target.name) {
        case "question":
          setQuestion(value);
          break;
        case "points":
          setPoints(value);
          break;
        default:
        // code block
      }
    }
  };

  const onEditQuizQuestionHanlder = async (question, index) => {
    window.$("#addEditQuizModal").scrollTop(0);
    setCurrentQueIndex(index);
    setQuestionId(question.id);
    setQuestion(question.question);
    setPoints(question.points);
    setIsAnsweredRadio(true);
    setFormValues(question.Option);
    setOptionData(question.Option);
    setIsUpdate(true);
    return true;
  };

  const onDeletetQuizQuestionHanlder = async (questionId, index) => {
    Swal.fire({
      title: props.t("Are_you_sure"),
      text: props.t("Able_to_revert_this"),
      icon: props.t("Warning"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: props.t("Yes_delete_it"),
      cancelButtonText: props.t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        const existQuestionData = _.cloneDeep(postQuestions);
        existQuestionData.splice(index, 1);
        setPostQuestions(existQuestionData);
        props.setQuizQuestions(existQuestionData);
        props.setAddedQuizCout(existQuestionData.length); 
      }
    });
  };

  const onUpdateQustionAndAnswer = async () => {
    let errors = await checkFormValidation(true);
    let errorsSize = Object.keys(errors).length;
    if (errorsSize != 0) {
      return false;
    }
    const existQuestionData = _.cloneDeep(postQuestions);
    const updateQuestionData = existQuestionData[currentQueIndex];
    updateQuestionData.question = question;
    updateQuestionData.points = points;
    updateQuestionData.Option = optionData;
    existQuestionData.splice(currentQueIndex, 1, updateQuestionData);
    setPostQuestions(existQuestionData);
    props.setQuizQuestions(existQuestionData);
    setIsSuccess(true);
    setSuccessMsg("Question Updated Successfully!");
    setIsAddedAnyQuestion(true);
    setQuestion("");
    setPoints("");
    setIsAnsweredRadio(false);
    window.$("#is_answer").attr("checked", false);
    window.$("#option").val("");
    setOptionData([]);
    setFormValues([{ option: "", is_answer: false },{ option: "", is_answer: false }]);
    setIsUpdate(false);
  };

  const formClear = () => {
    setQuestion("");
    setPoints("");
    setFormValues([{ option: "", is_answer: false },{ option: "", is_answer: false }]);
  }

  return (
    <>
      <div className="modal fade in" id="addEditQuizModal">
        <div className="modal-dialog scrollDialog modal-lg">
          <div className="modal-content mb-5">
            <div className="modal-header">
              <h4 className="modal-title">
                {props.questions.length > 0
                  ? `${props.t("Edit")} ${props.t("Questions")}`
                  : `${props.t("Add")} ${props.t("Questions")}`}
              </h4>
              <button 
                type="button" 
                className="close" 
                aria-label="Close"
                onClick={(e) => {
                  formClear();
                  window.$("#addEditQuizModal").modal("hide");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body scrollAndFixedHeight">
              {/* <div>
                <div className="row">
                  <div className="col-md-6">
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      onClick={(e) =>
                        window.$("#addEditQuizModal").modal("hide")
                      }
                      className="btn btn-success float-end"
                      disabled={!isAddedAnyQuestion ? true : false}
                    >
                      {props.t("Submit")} {props.t("Questions")}
                    </button>
                  </div>
                </div>
              </div> */}
              <div>
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <label htmlFor="question" className="form-label">
                      {props.t("Question")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props.t("Question")}
                      name="question"
                      onChange={inputChange}
                      value={question || ""}
                    />
                    {validateError.question ? (
                      <p className="text-danger text-small">
                        {validateError.question}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-2">
                    <label htmlFor="points" className="form-label">
                      {props.t("Points")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props.t("Points")}
                      name="points"
                      onChange={inputChange}
                      value={points || ""}
                    />
                    {validateError.points ? (
                      <p className="text-danger text-small">
                        {validateError.points}
                      </p>
                    ) : null}
                  </div>
                </div>
                {formValues.map((element, index) => (
                  <div className="row" key={`addform${index}`}>
                    <div className="col-sm-8 mb-2">
                      <label htmlFor="option" className="form-label">
                        {index + 1 + ". " + props.t("Option")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        key={`{option}` + index}
                        name="option"
                        type="text"
                        className="form-control addQuestionOptinClass"
                        id="option"
                        placeholder={props.t("Option")}
                        onChange={(e) => {
                          handleChange(index, e);
                          onHandleChange(e.target.value, "option", index);
                        }}
                        value={element.option || ""}
                      />
                    </div>
                    <div className="col-sm-2 mb-2">
                      <label htmlFor="is_answer" className="form-label">
                        {props.t("is_answer")} ?
                      </label>
                      <div className="">
                        <input
                          key={`{isAnswer}` + index}
                          name="is_answer"
                          type="radio"
                          className="addQuestionOptinClass"
                          id="is_answer"
                          onMouseUp={(e) => {
                            handleChange(index, e);
                            onHandleChange(e.target.value, "isAnswer", index);
                          }}
                          checked={element.is_answer ? true : false}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2 mb-2">
                      {index > 1 ? (
                        <div>
                          <a
                            href="#"
                            key={`{remove}` + index}
                            onClick={(e) => {
                              removeFormFields(index);
                              onRemoveOptionHandleChange(index);
                            }}
                            title="Delete"
                          >
                            <img
                              src={require("assets/images/delete-icon.png")}
                              width={15}
                              height={15}
                            />
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
                {validateError.optionsErr ? (
                  <p className="text-danger text-small">
                    {validateError.optionsErr}
                  </p>
                ) : null}
                {/* {props?.selectedPostType !== "OSCE" && ( */}
                  <div className="row">
                    <div className="col-sm-12 mb-2">
                      <button
                        color="success"
                        className="btn btn-success"
                        type="button"
                        onClick={(e) => addFormFields()}
                      >
                        {props.t("Add_More")}
                      </button>
                    </div>
                  </div>
                {/* )} */}
                <div className="row">
                  {isSuccess && (
                    <div className="col-sm-12 mb-2">
                      <div className="alert alert-success">
                        <strong>{successMsg}</strong>
                      </div>
                    </div>
                  )}
                  <div className="col-sm-12 mb-2">
                    <div className="flex-wrap gap-2 mt-3">
                      {isUpdate ? (
                        <button
                          type="button"
                          onClick={(e) => onUpdateQustionAndAnswer()}
                          className="btn btn-primary" 
                        >
                          {props.t("Update")}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={(e) => onSubmitQustionAndAnswer()}
                          className="btn btn-primary"
                        >
                          {props.t("Add")}
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={e => formClear()}
                        className="btn btn-secondary"
                        style={{marginLeft:"10px"}}
                      >
                        {props.t("Clear")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {postQuestions.length > 0 && (
                <>
                  <hr />
                  <div>
                    <h3 className="title mb-4">
                      {props.t("List-Question-Answers")}
                    </h3>
                  </div>
                </>
              )}
              <QuizQuestionList
                from="add"
                onEditQuizQuestionHanlder={onEditQuizQuestionHanlder}
                onDeletetQuizQuestionHanlder={onDeletetQuizQuestionHanlder}
                questions={postQuestions}
                isActionBtnVisible={props.isActionBtnVisible}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(CreateUpdateQuestionModal);
