import React from 'react'
import moment from 'moment';
import { convertToHTML } from "draft-convert"

export const dateFormat = (date) => {
  return moment(date).format("DD MMM YYYY");
};

export const dateFormatTH = (date) => {
  var options = {hour: 'numeric', minute: 'numeric', second: 'numeric'};
  return moment(date).add(543, 'years').format("DD MMM YYYY");
};

export const currentDateTH = (date) => {
  return moment().add(543, 'years').format("DD/MMM/YYYY");
};

export const dateTimeFormat = (datetime) => {
  return moment(datetime).format("DD MMMM YYYY hh:mm A");
};

export const TimeFormat = (datetime) => {
  return moment(datetime, "hh:mm").format('LT'); //moment(datetime).format("h:mm A UTC");
};

export const convertEditorContentTextToHtml = async (content) => {
  let currentContentTextAsHTML = convertToHTML({
    blockToHTML: b => {
      if (b.type === "atomic") {
        return {
          start: "<figure>",
          end: "</figure>",
          empty: "",
        }
      }
      return
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === "LINK") {
        return <a href={entity.data["url"]}>{originalText}</a>
      }
      if (entity.type === "IMAGE") {
        return {
          start: `<figure><img src='${entity.data["src"]}'>`,
          end: "</img></figure>",
          empty: "",
        }
      }
      return originalText
    },
  })(content.getCurrentContent())
  let strText = currentContentTextAsHTML
  strText = strText.replace(/<[^/>][^>]*><\/[^>]+>/, "")
  return strText;
}