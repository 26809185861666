// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';

import { CountryApi } from "apis/CountryApi";
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function Country(props) {
    const history = useHistory();
    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState([]);
    const [postCountryId, setCountryId] = useState('');
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    useEffect(() => {
        getAllCountry(activeData);
    }, []);

    const formClear = () => {
        validationType.resetForm();
        setCountry();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.country_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Country_Code"),
            selector: row => row.country_code,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Name"),
            selector: row => row.name,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getAllCountry = (data) => {
        setLoading(true);
        CountryApi.getCountries(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        country_id: sr_no,
                        country_code: v.country_code,
                        name: v.name,
                        action: (
                            <>

                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setCountryList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const onDelete = (id) => {

        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let CountryId = { "country_id": id };
                CountryApi.deleteCountry(CountryId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        setCountryId();
                        formClear()
                        getAllCountry(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (id) => {
        let countryId = { "country_id": id };
        setCountryId(id);
        CountryApi.getCountry(countryId)
            .then((res) => {
                setCountry(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Form validation 
    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            country_id: country ? country.id : "",
            country_code: country ? country.country_code : "",
            name: country ? country.name : "",
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                props.t("Required-Message")
            ),
            country_code: Yup.string().required(
                props.t("Required-Message")
            )
        }),
        onSubmit: (values) => {
            if (postCountryId) {
                values.country_id = postCountryId;
                CountryApi.updateCountry(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/countries');
                                    formClear();
                                    getAllCountry(activeData);
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }
            else {
                CountryApi.createCountry(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/countries');
                                    formClear()
                                    getAllCountry(activeData)
                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }

        }
    });

    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getAllCountry(data)
    }


    const handleRowChange = (v) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllCountry(data)
    }


    //meta title
    document.title = props.t("Countries") + ' | ' + props.t("Stuolio");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("Countries")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        progressPending={loading}
                                        columns={columns}
                                        data={countryList}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={activeData.totalPage}
                                        paginationPerPage={activeData.limit}
                                        defaultSortFieldID={1}
                                        onChangeRowsPerPage={value => handleRowChange(value)}
                                        onChangePage={value => handleChange(value)}
                                        sortable
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        highlightOnHover
                                    />
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Country_Code")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="country_code"
                                                    placeholder={props.t("Country_Code")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.country_code || ""}
                                                    invalid={
                                                        validationType.touched.country_code && validationType.errors.country_code ? true : false
                                                    }
                                                />
                                                {validationType.touched.country_code && validationType.errors.country_code ? (
                                                    <FormFeedback type="invalid">{validationType.errors.country_code}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Name")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="name"
                                                    placeholder={props.t("Name")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.name || ""}
                                                    invalid={
                                                        validationType.touched.name && validationType.errors.name ? true : false
                                                    }
                                                />
                                                {validationType.touched.name && validationType.errors.name ? (
                                                    <FormFeedback type="invalid">{validationType.errors.name}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
Country.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,

};
export default withTranslation()(Country);