// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DataTable from 'react-data-table-component';
import { SystemSettingApi } from "apis/SystemSettingApi"; 
import { useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";
import config from "config/config";
import constants from "../../../config/constants";
import Select from 'react-select'

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Button
} from "reactstrap";

import Swal from "sweetalert2";

function SystemSetting(props) {
    const history = useHistory();
    const [settingList, setSettingList] = useState([]);
    const [settings, setSettings] = useState([]);
    const [postSettingId, setPostSettingId] = useState('');
    const [defaultStatus, setDefaultStatus] = useState();
    const [defaultTab, setDefaultTab] = useState();
    const [defaultFormControlType, setDefaultFormControlType] = useState()
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    useEffect(() => {
        getAllSystemSettings(activeData);
    }, []);

    const formClear = () => {
        validationType.resetForm();
        setDefaultStatus();
        setDefaultTab();
        setDefaultFormControlType();
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.setting_id,
            sort: "asc",
            width: 40,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
            cell: row => <div style={{ width: "40px" }}>{row.setting_id}</div>,
        },
        {
            name: props.t("Key"),
            selector: row => row.config_key,
            sort: "asc",
            // width:400,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
            cell: row => <div style={{ width: "400px" }}>{row.config_key}</div>,
        },
        {
            name: props.t("Value"),
            selector: row => row.config_value,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Required"),
            selector: row => row.is_required,
            sort: "asc",
            width: 50,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false,
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 100,
        }
    ];

    const getAllSystemSettings = (data) => {
        setLoading(true);
        SystemSettingApi.getAllSystemSettings(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;

                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        setting_id: sr_no,
                        config_key: v.config_key,
                        config_value : v.config_value,
                        // comment : v.comment,
                        is_required: v.is_required == true ? (
                            <>
                                <span style={{ color: "green" }}>{props.t("Yes")}</span>
                            </>
                        ) : (
                            <>
                                <span style={{ color: "red" }}>{props.t("No")}</span>
                            </>
                        ),
                        action: (
                            <>
                                <i className="fas fa-edit" id="deletetooltip" style={{ color: "blue" }} onClick={() => onEdit(v.id)} />{" "}
                                <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                            </>
                        ),
                    };
                });
                setSettingList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const onDelete = (id) => {
        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let postSettingId = { "setting_id": id };
                SystemSettingApi.deleteSystemSetting(postSettingId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        setPostSettingId();
                        formClear();
                        getAllSystemSettings(activeData);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const onEdit = (id) => {
        let postSettingId = { "setting_id": id };
        setPostSettingId(id);
        SystemSettingApi.getSystemSetting(postSettingId)
            .then((res) => {
                setSettings(res.data.data);
                setDefaultStatus({
                    value : res.data?.data?.is_required,
                    label : constants.IS_REQUIRED[res.data?.data?.is_required]
                });
                setDefaultTab({
                    value : res.data?.data?.tab,
                    label : constants.SETTING_TAB[res.data?.data?.tab]
                });
                setDefaultFormControlType({
                    value : res.data?.data?.form_controls_type,
                    label : constants.FORM_CONTROL_TYPE[res.data?.data?.form_controls_type]
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Form validation 
    const validationType = useFormik({
        enableReinitialize: true,
        initialValues: {
            setting_id: settings ? settings.id : "",
            tab: settings ? settings.tab : "",
            config_key: settings ? settings.config_key : "",
            config_value: settings ? settings.config_value : "",
            comment: settings ? settings.comment : "",
            form_controls_type: settings ? settings.form_controls_type : "",
            // select_options: settings ? settings.select_options : "",
            is_required: settings ? settings.is_required : "",
        },
        validationSchema: Yup.object().shape({  
            config_key: Yup.string().required(
                props.t("Required-Message")
            ),
            config_value: Yup.string().required(
                props.t("Required-Message")
            ),
            comment: Yup.string().required(
                props.t("Required-Message")
            ),
            // select_options: Yup.string().required(
            //     props.t("Required-Message")
            // ),
        }),
        onSubmit: (values) => {
            if (postSettingId) {
                values.setting_id = postSettingId;
                SystemSettingApi.updateSystemSetting(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    history.push('/system-settings');
                                    setPostSettingId();
                                    formClear();
                                    setDefaultStatus();
                                    setDefaultTab();
                                    setDefaultFormControlType();
                                    getAllSystemSettings(activeData)
                                }
                            });
                        } else {
                            toastr.error(res.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }else {
                SystemSettingApi.createSystemSetting(values)
                    .then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                text: res.data.message,
                                icon: 'success',
                                imageAlt: 'success image',
                            }).then((result) => {
                                if (result?.isConfirmed) {
                                    history.push('/system-settings');
                                    formClear();
                                    setDefaultStatus({
                                        value : constants.STATUS[1],
                                        label : constants.STATUS[1]
                                    });
                                    getAllSystemSettings(activeData);
                                }
                            });
                        } else {
                            toastr.error(res?.data?.message, '');
                        }
                    }).catch(function (error) {
                        if (error?.response?.data?.message) {
                            toastr.error(error?.response?.data?.message, '');
                        }
                        else {
                            toastr.error(error?.response?.data?.error?.errors?.map((item, v) => `${item.msg}<br>`), '');
                        }
                    });
            }
            
        }
    });

    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getAllSystemSettings(data)
    }

    const handleRowChange = (v) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllSystemSettings(data)
    }

    //meta title
    document.title = props.t("System-Settings") + ' | ' + props.t("Stuolio");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Master")} breadcrumbItem={props.t("System-Settings")} />
            </div>
            <Container fluid={false}>
                <Row>
                    <Col lg={8}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <DataTable
                                        className="table-bordered"
                                        progressPending={loading}
                                        columns={columns}
                                        data={settingList}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={activeData.totalPage}
                                        paginationPerPage={activeData.limit}
                                        defaultSortFieldID={1}
                                        onChangeRowsPerPage={value => handleRowChange(value)}
                                        onChangePage={value => handleChange(value)}
                                        sortable
                                        noHeader
                                        defaultSortField="id"
                                        defaultSortAsc={false}
                                        highlightOnHover
                                    />
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody >
                                <Col sm={12}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validationType.handleSubmit();
                                            return false;
                                        }}>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Tab")}
                                                    {" "} <span className="text-danger">*</span></Label>
                                                <Select
                                                    className={
                                                        validationType.touched.type &&
                                                        validationType.errors.type
                                                            ? "bor "
                                                            : "text-dark"
                                                    }
                                                    styles={{
                                                        control: styles => ({
                                                            ...styles,
                                                        }),
                                                    }}
                                                    onChange={e => {
                                                        validationType.setFieldValue("tab", e.value)
                                                        setDefaultTab(e)
                                                    }}
                                                    value={defaultTab || ""}
                                                    onBlur={validationType.handleBlur}
                                                    name="tab"
                                                    options={Object.keys(constants.SETTING_TAB).map(
                                                        value => ({
                                                            value: value,
                                                            label: constants.SETTING_TAB[value],
                                                        })
                                                    )}
                                                    placeholder={
                                                        props.t("Please_Select_Type")
                                                    }
                                                />
                                                {validationType.touched.status && validationType.errors.status ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Key")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="config_key"
                                                    placeholder={props.t("Key")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.config_key || ""}
                                                    invalid={
                                                        validationType.touched.config_key && validationType.errors.config_key ? true : false
                                                    }
                                                />
                                                {validationType.touched.config_key && validationType.errors.config_key ? (
                                                    <FormFeedback type="invalid">{validationType.errors.config_key}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Value")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="config_value"
                                                    placeholder={props.t("Value")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.config_value || ""}
                                                    invalid={
                                                        validationType.touched.config_value && validationType.errors.config_value ? true : false
                                                    }
                                                />
                                                {validationType.touched.config_value && validationType.errors.config_value ? (
                                                    <FormFeedback type="invalid">{validationType.errors.config_value}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Form_Control_Types")}
                                                    {" "} <span className="text-danger">*</span></Label>
                                                <Select
                                                    className={
                                                        validationType.touched.form_controls_type &&
                                                        validationType.errors.form_controls_type
                                                            ? "bor "
                                                            : "text-dark"
                                                    }
                                                    styles={{
                                                        control: styles => ({
                                                            ...styles,
                                                        }),
                                                    }}
                                                    onChange={e => {
                                                        validationType.setFieldValue("form_controls_type", e.value)
                                                        setDefaultFormControlType(e)
                                                    }}
                                                    value={defaultFormControlType || ""}

                                                    onBlur={validationType.handleBlur}
                                                    name="tab"
                                                    options={Object.keys(constants.FORM_CONTROL_TYPE).map(
                                                        value => ({
                                                            value: value,
                                                            label: constants.FORM_CONTROL_TYPE[value],
                                                        })
                                                    )}
                                                    placeholder={
                                                        props.t("Please_Select_Type")
                                                    }
                                                />
                                                {validationType.touched.form_controls_type && validationType.errors.form_controls_type ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.form_controls_type}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Comment")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="comment"
                                                    placeholder={props.t("Comment")}
                                                    type="textarea"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.comment || ""}
                                                    invalid={
                                                        validationType.touched.comment && validationType.errors.comment ? true : false
                                                    }
                                                />
                                                {validationType.touched.comment && validationType.errors.comment ? (
                                                    <FormFeedback type="invalid">{validationType.errors.comment}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        {/* <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Options")}{" "} <span className="text-danger">*</span></Label>
                                                <Input
                                                    name="select_options"
                                                    placeholder={props.t("Options")}
                                                    type="text"
                                                    onChange={validationType.handleChange}
                                                    onBlur={validationType.handleBlur}
                                                    value={validationType.values.select_options || ""}
                                                    invalid={
                                                        validationType.touched.select_options && validationType.errors.select_options ? true : false
                                                    }
                                                />
                                                {validationType.touched.select_options && validationType.errors.select_options ? (
                                                    <FormFeedback type="invalid">{validationType.errors.select_options}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col> */}

                                        <Col sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">{props.t("Is_Required")}
                                                    {" "} <span className="text-danger">*</span></Label>
                                                <Select
                                                    className={
                                                        validationType.touched.is_required &&
                                                        validationType.errors.is_required
                                                            ? "bor "
                                                            : "text-dark"
                                                    }
                                                    styles={{
                                                        control: styles => ({
                                                            ...styles,
                                                        }),
                                                    }}
                                                    onChange={e => {
                                                        validationType.setFieldValue("is_required", e.value)
                                                        setDefaultStatus(e)
                                                    }}
                                                    value={defaultStatus || ""}
                                                    onBlur={validationType.handleBlur}
                                                    name="is_required"
                                                    options={Object.keys(constants.IS_REQUIRED).map(
                                                        value => ({
                                                            value: value,
                                                            label: constants.IS_REQUIRED[value],
                                                        })
                                                    )}
                                                    placeholder={
                                                        props.t("Select_Status")
                                                    }
                                                />
                                                {validationType.touched.is_required && validationType.errors.is_required ? (
                                                    <div style={{ color: '#f46a6a', fontSize: '11px', marginTop: '3px' }}>
                                                        {validationType.errors.is_required}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" >
                                                {props.t("Submit")}
                                            </Button>{" "}
                                            <Button color="secondary" onClick={e => formClear()}>
                                                {props.t("Clear")}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

SystemSetting.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,
};
export default withTranslation()(SystemSetting);