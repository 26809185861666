// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from "components/Common/Breadcrumb";
import DataTable from 'react-data-table-component';
import { useHistory, Link } from "react-router-dom"
import { withTranslation } from "react-i18next";
import config from "config/config";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { PostApi } from "apis/PostApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import * as url from "../../helpers/common_helper";

function PostQuestions(props) {
    const history = useHistory();
    const [files1, setFiles1] = useState([]);
    const [postList, setPostList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    useEffect(() => {
        getAllPosts(activeData);
    }, []);

    const formClear = () => {
        validationType.resetForm();
        setFiles1([])
    };

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.post_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Title"),
            selector: row => row.title,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Scheduled Date"),
            selector: row => row.published_date,
            sort: "asc",
            width: 400
        },
        {
            name: props.t("Visible"),
            selector: row => row.visible_to,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Created At"),
            selector: row => row.created_at,
            sort: "asc",
            width: 400
        },
        {
            name: props.t("Action"),
            selector: row => row.action,
            sort: "asc",
            width: 200,
        }
    ];

    const getAllPosts = (data) => {
        setLoading(true);
        PostApi.getAllQuestionPosts(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;
                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        post_id: sr_no,
                        title: v.title ? v.title : "-",
                        published_date: v.published_date ? url.dateFormat(v.published_date) : "-",
                        visible_to: v.visible_to ? v.visible_to : "-",
                        created_at: url.dateTimeFormat(v.created_at),
                        action: (
                            <>
                                <Link
                                    style={{ marginRight: "15px" }}
                                    to={`/edit-post-questions/${v.id}`}
                                >
                                    <i className=" fas fa-edit" id="edittooltip" />
                                </Link>
                                <Link
                                    to={`#`}
                                >
                                    <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                                </Link>

                            </>
                        ),

                    };
                });
                setPostList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const onDelete = (id) => {
        Swal.fire({
            title: props.t("Are_you_sure"),
            text: props.t("Able_to_revert_this"),
            icon: props.t("Warning"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: props.t("Yes_delete_it"),
            cancelButtonText: props.t("Cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                let PostId = { "post_id": id };
                PostApi.deletePost(PostId)
                    .then((res) => {
                        Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
                        getAllPosts(activeData)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

   
    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getAllPosts(data)
    }


    const handleRowChange = (v) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllPosts(data)
    }

    //meta title
    document.title = props.t("Questionnaires") + ' | ' + props.t("Stuolio");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Question_Post")} breadcrumbItem={props.t("Questionnaires")} />
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <Link to="/create-post-questions" className="btn btn-primary w-xs btn-right">{props.t("Add")}</Link>
                        </Col>
                    </Row>
                </Container>
                <Container fluid={false}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    <Col sm={12}>
                                        <DataTable
                                            className="table-bordered"
                                            progressPending={loading}
                                            columns={columns}
                                            data={postList}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

PostQuestions.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PostQuestions);