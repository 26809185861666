let constants = {
  STATUS: {
    1: "Active",
    0: "Deactive",
  },
  ROLE: {
    SUPERADMIN: 1,
    CONSULTANT: 2,
    STUDENT: 3,
  },
  USER_STATUS : {
    Pending : "Pending",
    Approved : "Approved",
    Rejected : "Rejected"
  },
  PAGE_TYPES:{
    Privacy_Policy: "Privacy Policy",
    About_Us: "About Us",
    Terms_Of_Use: "Terms of Use",
    Contact_Us: "Contact Us",
  },

  DEVICE_TYPE:{
    Android: "Android",
    iOS: "iOS",
  },

  SETTING_TAB: {
    GENERAL: "GENERAL",
    SMTP: "SMTP"
  },

  FORM_CONTROL_TYPE:{
    SELECT: "SELECT",
    INPUT: "INPUT",
    TEXTAREA: "TEXTAREA"
  },

  IS_REQUIRED: {
    1: "YES",
    0: "NO",
  },
  IMAGE_VALIDATION: {
    POST_IMAGE: 2097152
  },
  FILE_VALIDATION : {
    POST_REEL : 10485760
  },
  POST_TYPES: {
    AdminPost : "AdminPost",
    AdminReel : "AdminReel",
  },
  VISIBLE_TO : {
    All : "All",
    Consultant : "Consultant",
    Student : "Student"
  },
  POST_MAX_QUESTIONS : 20,
  POST_MESSAGE_CHAR_LIMIT : 2500
}

export default constants