import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import UpdatePassword from "pages/Authentication/UpdatePassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import ProfileRoles from "../pages/Profile/Role/Roles"
import ProfileAddRoles from "../pages/Profile/Role/AddRoles"
import ProfileEditRoles from "../pages/Profile/Role/EditRoles"
import Permission from "../pages/Profile/Role/Permission"
import UpdateProfile from "../pages/Profile/UserProfile/UpdateProfile"
import ChangePassword from "../pages/Profile/UserProfile/ChangePassword"
import PageContent from "pages/Master/PageContent/PageContent"
import EditPageContent from "pages/Master/PageContent/EditPageContent"
import SystemSetting from "pages/Setting/SystemSetting/SystemSetting"
import LoginHistory from "pages/Reports/LoginHistory"
import Country from "pages/Master/Country/Country"
import States from "pages/Master/States/States"
import City from "pages/Master/City/City"

import Academic from "pages/Master/Academic/Academic"
import Sport from "pages/Master/Sport/Sport"
import Curricular from "pages/Master/Curricular/Curricular"
import FocusArea from "pages/Master/FocusArea/FocusArea"
import Grade from "pages/Master/Grade/Grade"
import Service from "pages/Master/Service/Service"
import Speciality from "pages/Master/Speciality/Speciality"
import Proficiency from "pages/Master/Proficiency/Proficiency"
import Badge from "pages/Master/Badge/Badge"
import Interest from "pages/Master/Interest/Interest"
import DreamCollege from "pages/Master/DreamCollege/DreamCollege"
import Language from "pages/Master/Language/Language"
import ReportType from "pages/Master/ReportType/ReportType"
import Report from "pages/Report/Report"
import NotificationType from "pages/Master/NotificationType/NotificationType"
import AppVersion from "pages/Master/AppVersion/AppVersion";
import Student from "pages/Profile/Student/Student";
import Consultant from "pages/Profile/Consultant/Consultant";
import ApprovalRequest from "pages/ApprovalRequest/ApprovalRequest";
import EditApprovalRequest from "pages/ApprovalRequest/EditApprovalRequest";

import Post from "pages/Post/Posts";
import CreatePost from "pages/Post/CreatePost";
import EditPost from "pages/Post/EditPost";

import PostQuestion from "pages/PostQuestion/PostQuestions";
import CreatePostQuestion from "pages/PostQuestion/CreatePostQuestion";
import EditPostQuestion from "pages/PostQuestion/EditPostQuestion";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // Profile
  { path: "/roles", component: ProfileRoles },
  { path: "/add-role", component: ProfileAddRoles },
  { path: "/edit-role/:role_id", component: ProfileEditRoles },
  { path: "/permission/:role_id", component: Permission },

  { path: "/countries", component: Country },
  { path: "/states", component: States },
  { path: "/cities", component: City },
  { path: "/page-contents", component: PageContent },
  { path: "/edit-page-content/:page_content_id", component: EditPageContent },
  { path: "/system-settings", component: SystemSetting },

  // Non Module Route
  { path: "/update-profile", component: UpdateProfile },
  { path: "/change-password", component: ChangePassword },

  { path: "/sports", component: Sport },
  { path: "/academics", component: Academic },
  { path: "/curriculars", component: Curricular },
  { path: "/focus-areas", component: FocusArea },
  { path: "/grades", component: Grade },
  { path: "/services", component: Service },
  { path: "/specialities", component: Speciality },
  { path: "/badges", component: Badge },
  { path: "/proficiencies", component: Proficiency },
  { path: "/interests", component: Interest },
  { path: "/dream-colleges", component: DreamCollege },
  { path: "/app-versions", component: AppVersion },
  { path: "/languages", component: Language },
  { path: "/report-types", component: ReportType },
  { path: "/notification-types", component: NotificationType },

  { path: "/approval-Requests", component: ApprovalRequest },
  { path: "/edit-approval-Request/:request_id", component: EditApprovalRequest },

  { path: "/students", component: Student },
  { path: "/consultants", component: Consultant },

  { path: "/posts", component: Post },
  { path: "/create-post", component: CreatePost },
  { path: "/edit-post/:post_id", component: EditPost },

  { path: "/post-questions", component: PostQuestion },
  { path: "/create-post-questions", component: CreatePostQuestion },
  { path: "/edit-post-questions/:post_id", component: EditPostQuestion },
  
  { path: "/reports", component: Report },


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/update-password", component: UpdatePassword },
  { path: "/register", component: Register },

  // { path: "/pages-maintenance", component: PagesMaintenance },
  // { path: "/pages-comingsoon", component: PagesComingsoon },
  // { path: "/pages-404", component: Pages404 },
  // { path: "/pages-500", component: Pages500 },
  // { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { authProtectedRoutes, publicRoutes }
