import axios from "axios";
import config from "../config/config";
import * as url from "../helpers/url_helper";

export const CommanApi = {
  getSystemSetting: async (data) => {
    var axiosConfig = {
      method: 'post',
      url: `${config.API_URL}${url.GET_SYSTEM_SETTING}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    return await axios(axiosConfig);
  },
};