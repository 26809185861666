// src/components/filter.
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from "components/Common/Breadcrumb";
import DataTable from 'react-data-table-component';
import { useHistory, Link } from "react-router-dom"
import { withTranslation } from "react-i18next";
import config from "config/config";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { ReportApi } from "apis/ReportApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import * as url from "../../helpers/common_helper";

function Report(props) {
    const history = useHistory();
    const [reportList, setReportList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [activeData, setActiveData] = useState({
        page: 1,
        totalPage: 1,
        search: "",
        limit: config.LIMIT
    });

    useEffect(() => {
        getAllReports(activeData);
    }, []);

    const columns = [
        {
            name: props.t("Sr_No"),
            selector: row => row.report_id,
            sort: "asc",
            width: 150,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        // {
        //     name: props.t("Report Type"),
        //     selector: row => row.report_type_id,
        //     sort: "asc",
        //     width: 270,
        //     sortable: true,
        //     defaultSortField: true,
        //     defaultSortAsc: false
        // },
        {
            name: props.t("User Name"),
            selector: row => row.user_id,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Reported By"),
            selector: row => row.reported_by,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Message"),
            selector: row => row.message,
            sort: "asc",
            width: 270,
            sortable: true,
            defaultSortField: true,
            defaultSortAsc: false
        },
        {
            name: props.t("Reported At"),
            selector: row => row.created_at,
            sort: "asc",
            width: 400
        },
        // {
        //     name: props.t("Action"),
        //     selector: row => row.action,
        //     sort: "asc",
        //     width: 200,
        // }
    ];

    const getAllReports = (data) => {
        setLoading(true);
        ReportApi.getAllReports(data)
            .then((res) => {
                setActiveData({
                    page: activeData.page,
                    totalPage: res?.data?.data?.count,
                    search: activeData.search,
                    limit: activeData.limit,
                });
                let data = [];
                var sr_no = res.data.sr_no_start;
                res?.data?.data?.rows?.forEach((v, i) => {
                    sr_no = sr_no + 1;
                    data[i] = {
                        report_id: sr_no,
                        // report_type_id: v?.ReportType?.name,
                        type: v?.ReportType?.name,
                        user_id: v.User?.first_name + ' ' +v.User?.last_name,
                        reported_by: v.ReportedBy?.first_name + ' ' +v.ReportedBy?.last_name,
                        message: v.message,
                        created_at: url.dateFormat(v.created_at),
                        // action: (
                        //     <>

                        //         <Link
                        //             style={{ marginRight: "15px" }}
                        //             to={`/edit-post/${v.id}`}
                        //         >
                        //             <i className=" fas fa-edit" id="edittooltip" />
                        //         </Link>
                        //         <Link
                        //             to={`#`}
                        //         >
                        //             <i className="mdi mdi-delete font-size-18" style={{ color: "red" }} id="deletetooltip" onClick={() => onDelete(v.id)} />
                        //         </Link>

                        //     </>
                        // ),
                    };
                });
                setReportList(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    // const onDelete = (id) => {
    //     Swal.fire({
    //         title: props.t("Are_you_sure"),
    //         text: props.t("Able_to_revert_this"),
    //         icon: props.t("Warning"),
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#f46a6a",
    //         confirmButtonText: props.t("Yes_delete_it"),
    //         cancelButtonText: props.t("Cancel"),
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             let PostId = { "post_id": id };
    //             PostApi.deletePost(PostId)
    //                 .then((res) => {
    //                     Swal.fire(props.t("Deleted"), res.data.message, props.t("Success"));
    //                     getAllReports(activeData)
    //                 })
    //                 .catch((err) => {
    //                     console.log(err);
    //                 });
    //         }
    //     });
    // };

   
    const handleChange = (v) => {
        setActiveData({ page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit });
        const data = { page: v, totalPage: activeData.totalPage, search: activeData.search, limit: activeData.limit }
        getAllReports(data)
    }


    const handleRowChange = (v) => {
        setActiveData({ page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v });
        const data = { page: activeData.page, totalPage: activeData.totalPage, search: activeData.search, limit: v }
        getAllReports(data)
    }

    //meta title
    document.title = props.t("Reports") + ' | ' + props.t("Stuolio");

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title={props.t("Report")} breadcrumbItem={props.t("Reports")} />
                {/* <Container fluid={true}>
                    <Row>
                        <Col>
                            <Link to="/create-report" className="btn btn-primary w-xs btn-right">{props.t("Add")}</Link>
                        </Col>
                    </Row>
                </Container> */}
                <Container fluid={false}>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    <Col sm={12}>
                                        <DataTable
                                            className="table-bordered"
                                            progressPending={loading}
                                            columns={columns}
                                            data={reportList}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={activeData.totalPage}
                                            paginationPerPage={activeData.limit}
                                            defaultSortFieldID={1}
                                            onChangeRowsPerPage={value => handleRowChange(value)}
                                            onChangePage={value => handleChange(value)}
                                            sortable
                                            noHeader
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            highlightOnHover
                                        />
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

Report.propTypes = {
    t: PropTypes.any,
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(Report);