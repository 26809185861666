import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import { getRoleID } from "../../Token"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import config from "config/config"
import DashboardCard from "./DashboardCard"
import constants from "config/constants"
import { AdminApi } from "../../apis/AdminApi"

const Dashboard = props => {
  const [dashboardCounts, setDashboardCounts] = useState({});
  const getDashboardCounts = () => {
    AdminApi.getDashboardCounts()
      .then((res) => {
        if (res.data.success) {
          setDashboardCounts(res.data);
        } else {
          setDashboardCounts("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDashboardCounts();
  }, [])

  //meta title
  document.title = props.t("Dashboard") + " | " + props.t("Stuolio")
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          {getRoleID() === constants.ROLE.SUPERADMIN && (
            <>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Consultants")}
                    path="/consultants"
                    totalCount={dashboardCounts?.consultantsCount ?? 0}
                    iconName="fa-solid fa-chalkboard-teacher font-size-24"
                  />
                </Col>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Students")}
                    path="/students"
                    totalCount={dashboardCounts?.studentsCount ?? 0}
                    iconName="fa-solid fa-users font-size-24"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Dream-Colleges")}
                    path="/dream-colleges"
                    totalCount={dashboardCounts?.dreamCollegesCount ?? 0}
                    iconName="fa-solid fa-university font-size-24"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <DashboardCard
                    title={props.t("Consultant-Specialities")}
                    path="/specialities"
                    totalCount={dashboardCounts?.specialitiesCount ?? 0 }
                    iconName="fa-solid fa-university font-size-24"
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
